<template>
  <Regenerated v-show="false" ref="regeneratedRef" :type="props.type" />
  <el-scrollbar
    :height="
      props.type === 'TRANSACTIONS' || props.type === 'GENERAL_LEDGER' || props.type === 'DASHBOARD' ? '93vh' : '78vh'
    "
  >
    <div class="elv-report-guide-step-container">
      <div class="elv-report-guide-step-content">
        <p class="elv-report-guide-step-title">{{ t('report.projectHasBeenCreated') }}</p>
        <p class="elv-report-guide-step-info">{{ t('report.getStartedOnFewEasySteps') }}</p>
        <ul class="elv-report-guide-step-list">
          <li
            v-for="(item, index) in guideStepDataList"
            :key="index"
            :class="{ 'is-active': reportStore.entityDetail?.guideStep === index }"
          >
            <div class="elv-report-guide-step-item__index">{{ index + 1 }}</div>
            <p class="elv-report-guide-step-item__title">{{ item.title }}</p>
            <SvgIcon class="elv-report-guide-step-item__icon" :name="item.icon" width="120" height="90" />
            <p class="elv-report-guide-step-item__info">{{ item.info }}</p>
            <div
              v-if="reportStore.entityDetail?.guideStep > index && reportStore.entityDetail?.guideStep !== 0"
              class="elv-report-guide-step-item__done"
            >
              <SvgIcon name="report-guide-step-done" width="21" height="21" />
              <span>{{ t('common.done') }}</span>
            </div>
            <elv-button
              v-else-if="regeneratedRef?.regenerating && reportStore.entityDetail?.guideStep > 1"
              width="140"
              height="36"
              :disabled="true"
              class="elv-reports-regenerating"
            >
              <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
              <span>{{ t('report.regenerating') }}</span></elv-button
            >
            <elv-button
              v-else
              width="140"
              height="36"
              :disabled="reportStore.entityDetail?.guideStep < index"
              @click="onJumpStepPage"
              >{{ t('button.start') }}</elv-button
            >
          </li>
        </ul>
      </div>

      <div class="elv-report-guide-step-help">
        <p class="elv-report-guide-step-help-title">{{ t('report.needHelp') }}</p>
        <div class="elv-report-guide-step-help-content">
          <div class="elv-report-guide-step-help-contact" @click="openJumpCalendar">
            <div class="elv-report-guide-step-help-header__icon">
              <img src="@/assets/img/landing-price-contact.png" alt="contact" />
              <SvgIcon
                name="arrow-right-line"
                width="24"
                height="24"
                class="elv-report-guide-step-help-header__icon-arrow"
              />
            </div>
            <p class="elv-report-guide-step-help-title__text">{{ t('report.bookDemoTitle') }}</p>
            <p class="elv-report-guide-step-help-info__text">
              {{ t('report.bookDemoInfo') }}
            </p>
          </div>
          <div class="elv-report-guide-step-help-docs" @click="openJumpDocs">
            <div class="elv-report-guide-step-help-header__icon">
              <div>
                <SvgIcon name="book-open-line" width="24" height="24" />
              </div>
              <SvgIcon
                name="arrow-right-line"
                width="24"
                height="24"
                class="elv-report-guide-step-help-header__icon-arrow"
              />
            </div>
            <p class="elv-report-guide-step-help-title__text">{{ t('report.ReadDocsTitle') }}</p>
            <p class="elv-report-guide-step-help-info__text">
              {{ t('report.ReadDocsInfo') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Regenerated from './Regenerated.vue'
import { useReportStore } from '@/stores/modules/reports/index'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  type: {
    type: String,
    default: ''
  }
})

const { t } = useI18n()
const router = useRouter()
const reportStore = useReportStore()

const regeneratedRef = ref()
const guideStepDataList = [
  {
    title: t('report.addSources'),
    info: t('report.addSourcesInfo'),
    icon: 'report-guide-step-sources'
  },
  {
    title: t('report.reviewTransactions'),
    info: t('report.reviewTransactionsInfo'),
    icon: 'report-guide-step-transactions'
  },
  {
    title: t('report.generateReports'),
    info: t('report.generateReportsInfo'),
    icon: 'report-guide-step-reports'
  }
]

// eslint-disable-next-line no-unused-vars
const stepHeight = computed(() => {
  return regeneratedRef.value?.isShowRegeneratedInfo ? `calc(100vh - 214px)` : `calc(100vh - 88px)`
})

const onJumpStepPage = () => {
  switch (reportStore.entityDetail?.guideStep) {
    case 0:
      router.push({
        name: 'reports-sources-detail'
      })
      break
    case 1:
      router.push({
        name: 'reports-transactions-for-review'
      })
      break
    default:
      regeneratedRef.value?.onClickedRegenerate()
      break
  }
}

const openJumpCalendar = () => {
  window.open('https://elvencom.feishu.cn/scheduler/d4b862ba3c9a4d2d', '_target')
}
const openJumpDocs = () => {
  window.open('https://docs.elven.com', '_target')
}
</script>

<style lang="scss" scoped>
.elv-report-guide-step-container {
  width: 100%;
  // height: v-bind('stepHeight');
  margin-top: 38px;
  padding-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .elv-report-guide-step-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .elv-report-guide-step-title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #0e0f11;
  }

  .elv-report-guide-step-info {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #aaafb6;
    margin-top: 10px;
    margin-bottom: 24px;
  }

  .elv-report-guide-step-list {
    display: flex;

    li {
      box-sizing: border-box;
      width: 300px;
      height: 419px;
      background: #ffffff;
      border: 1px solid #d0d4d9;
      border-radius: 8px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:last-child {
        margin-right: 0;
      }

      &.is-active {
        background: #f5f8ff;
        border: 1px solid #5e85eb;
        box-shadow: 0px 2px 10px rgba(228, 232, 242, 0.7);

        .elv-report-guide-step-item__index {
          background: linear-gradient(101.06deg, #1351f0 -1.56%, #1a47e8 28.16%, #0085ff 106.1%);
        }

        .elv-report-guide-step-item__icon {
          stroke: #1753eb;
          color: #1753eb;
        }
      }

      .elv-report-guide-step-item__index {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        background: #bfc7dd;
        border-radius: 13px;
        font-family: 'Commissioner';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        leading-trim: both;
        text-edge: cap;
        letter-spacing: -0.03em;
        color: #ffffff;
      }

      .elv-report-guide-step-item__title {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #0e0f11;
        margin-top: 10px;
        margin-bottom: 24px;
      }

      .elv-report-guide-step-item__icon {
        stroke: #bfc7dd;
        color: #bfc7dd;
        fill: transparent;
      }

      .elv-report-guide-step-item__info {
        width: 244px;
        height: 54px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #1e2024;
        margin-top: 24px;
        margin-bottom: 73px;
      }

      .elv-report-guide-step-item__done {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 36px;

        svg {
          margin-right: 9.5px;
        }

        span {
          font-family: 'Plus Jakarta Sans';
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: #000000;
        }
      }

      .elv-reports-regenerating {
        display: flex;
        align-items: center;

        img {
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 8px;
          animation: loading-rotate 2s linear infinite;
        }

        span {
          font-family: 'Plus Jakarta Sans';
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: #1e2024;
        }
      }
    }
  }

  .elv-report-guide-step-help {
    margin-top: 16px;
    width: 920px;

    .elv-report-guide-step-help-title {
      color: #838d95;
      font-size: 14px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 7px;
    }

    .elv-report-guide-step-help-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .elv-report-guide-step-help-contact,
    .elv-report-guide-step-help-docs {
      width: 450px;
      height: 160px;
      box-sizing: border-box;
      padding: 20px 18px 18px 20px;
      border-radius: 5px;
      border: 1px solid #ede9fd;
      background: #fbfaff;
      cursor: pointer;

      .elv-report-guide-step-help-title__text {
        color: #0e0f11;
        font-size: 16px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 4px;
      }

      .elv-report-guide-step-help-info__text {
        color: #636b75;
        font-size: 12px;
        font-weight: 400;
        font-family: 'Plus Jakarta Sans';
        line-height: 16px;
      }
    }

    .elv-report-guide-step-help-docs {
      .elv-report-guide-step-help-header__icon {
        margin-bottom: 9px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
          width: 48px;
          height: 48px;
          border-radius: 48px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .elv-report-guide-step-help-header__icon-arrow {
          fill: #1343bf;
        }
      }
    }

    .elv-report-guide-step-help-contact {
      border: 1px solid #e6ece9;
      background: #f6fffa;

      .elv-report-guide-step-help-header__icon {
        margin-bottom: 9px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 130px;
          height: 48px;
          display: block;
        }
      }
    }
  }
}
@keyframes loading-rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
