<template>
  <div v-if="isShowRegeneratedInfo" class="elv-financials-header-regenerated">
    <template v-if="reportStore.entityDetail.status === 'WAITING' || reportStore.entityDetail.status === 'FINISHED'">
      <div v-if="isErrorReport && reportStore.entityDetail.status === 'FINISHED'" class="elv-report-error-container">
        <div>
          <img src="@/assets/img/reports/table-warning-filled.png" alt="error" /> {{ t('message.reportError') }}
        </div>
      </div>
      <template v-else>
        <p class="elv-financials-header-regenerated__title">
          {{ reportStore.entityDetail.status === 'FINISHED' ? t('report.regenerated') : t('report.regenerating') }}
        </p>

        <el-progress
          :stroke-width="6"
          color="#5E85EB"
          :percentage="progressPercentData.percent"
          @vue:updated="onProgressUpdated"
          @vue:mounted="onProgressMounted"
        >
          <swiper
            class="elv-financials-header-regenerated-swiper"
            :modules="modules"
            :mousewheel="false"
            :free-mode="false"
            direction="vertical"
            :allow-touch-move="false"
            :nested="true"
            @swiper="onSwiper"
          >
            <swiper-slide v-for="(item, index) in projectStatus" :key="index" class="swiper-slide">
              {{ transformI18n(item.des) }}</swiper-slide
            >
          </swiper>
          <!-- <span> {{ progressPercentData?.statusDetail?.des }} </span> -->
        </el-progress>
      </template>
    </template>

    <template v-if="reportStore.entityDetail.status === 'FAILED'">
      <SvgIcon class="elv-financials-header-regenerated-error__icon" name="sources-sync-error" width="18" height="18" />
      <p class="elv-financials-header-regenerated__title">
        {{ t('message.regenerateFailed') }}
      </p>
    </template>

    <SvgIcon
      v-if="reportStore.entityDetail.status === 'FAILED' || reportStore.entityDetail.status === 'FINISHED'"
      class="elv-financials-header-regenerated__close"
      name="close"
      width="18"
      height="18"
      @click="onCloseRegeneratedReport"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { FreeMode } from 'swiper'
import { emitter } from '@/lib/mitt'
import { find, isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'
import { $t, transformI18n } from '@/i18n'
import { Swiper, SwiperSlide } from 'swiper/vue'
import useInitPageIntro from '@/hooks/initPageIntro'
import { useReportStore } from '@/stores/modules/reports/index'

const props = defineProps({
  type: {
    type: String,
    default: ''
  }
})

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const { initPageIntro } = useInitPageIntro()

const progressPercent = ref(0)
const financeEntityId: any = ref(0)
const regenerating = ref(false)
const allowDisplayGuidance = ref(true)
const isShowRegeneratedInfo = ref(false)
const syncTimer: any = ref(null)
const progressTimer: any = ref(null)
const regeneratedSwiperRef = ref()
const swiperActiveIndex = ref(0)

const modules: any[] = [FreeMode]

const projectStatus = ref([
  {
    status: 'WAITING',
    des: $t('report.Rule Engine Configure Accounting Entries and Cost Basis')
  },
  {
    status: 'GENERATE_ACCOUNTING',
    des: $t('report.Generate Income Statement report')
  },
  {
    status: 'INCOME_STATEMENT',
    des: $t('report.Generate Balance Sheet report')
  },
  {
    status: 'BALANCE_SHEET',
    des: $t('report.Generate Restrictions of Crypto Assets report')
  },
  {
    status: 'RESTRICTION',
    des: $t('report.Generate Cash Flow Statement report')
  },
  {
    status: 'CASH_FLOW_STATEMENT',
    des: $t('report.Generate Crypto Assets Roll Forward report')
  },
  {
    status: 'ROLL_FORWARD',
    des: $t('report.Generate Significant Holdings report')
  },
  {
    status: 'SIGNIFICANT_HOLDING',
    des: $t('report.Generate note L1')
  },
  {
    status: 'NOTEL1',
    des: $t('report.Generate note L2')
  },
  {
    status: 'NOTEL2',
    des: $t('report.Tabulating')
  },
  {
    status: 'FINISHED',
    des: $t('report.Reports have been regenerated successfully')
  }
])

const isErrorReport = computed(() => {
  return !isEmpty(find(reportStore.issueList, { type: props.type }))
})

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

const progressPercentData: any = computed(() => {
  let selectIndex = 0
  let statusDetail: any
  projectStatus.value.forEach((item, index) => {
    if (item.status.indexOf(reportStore.entityDetail.progressStatus) !== -1) {
      statusDetail = item
      selectIndex = index
    }
  })

  if (statusDetail?.status === 'WAITING') {
    if (progressTimer.value === null) {
      progressTimer.value = setInterval(() => {
        if (progressPercent.value < 69 && statusDetail?.status === 'WAITING') {
          progressPercent.value += 1
        } else {
          clearInterval(progressTimer.value)
          progressTimer.value = null
        }
      }, 1800000 / 69)
    }
  }
  if (statusDetail?.status === 'GENERATE_ACCOUNTING') {
    progressPercent.value = 70
  } else if (statusDetail?.status !== 'WAITING' && statusDetail?.status !== 'FINISHED') {
    progressPercent.value = Math.floor(((selectIndex + 1) / projectStatus.value.length) * 30) + 70
  } else if (statusDetail?.status === 'FINISHED') {
    progressPercent.value = 100
  }
  return {
    index: selectIndex,
    percent: progressPercent.value,
    statusDetail
  }
})

// onSwiper 事件
const onSwiper = (swiper: any) => {
  swiperActiveIndex.value = swiper.activeIndex
  regeneratedSwiperRef.value = swiper
}

const onProgressMounted = () => {
  if (regeneratedSwiperRef.value) {
    nextTick(() => {
      // regeneratedSwiperRef.value?.slideTo(0, 0, false)
      regeneratedSwiperRef.value?.slideTo(progressPercentData.value?.index, 0, false)
    })
  }
}

const onProgressUpdated = () => {
  if (regeneratedSwiperRef.value) {
    nextTick(() => {
      regeneratedSwiperRef.value?.slideTo(progressPercentData.value?.index)
    })
  }
}

const onCloseRegeneratedReport = () => {
  reportStore.editRegenerateReport(entityId.value, true, true, props.type)
  isShowRegeneratedInfo.value = false
  regenerating.value = false
  progressPercent.value = 0
}

const regenerateReportInit = () => {
  const isInRegenerateReportList = reportStore.regenerateReportList.find((e: any) => {
    return e.entityId === entityId.value
  })
  isShowRegeneratedInfo.value =
    ((reportStore.entityDetail?.status === 'WAITING' || reportStore.entityDetail?.status === 'FAILED') &&
      reportStore.entityDetail.operationTime !== null) ||
    (isInRegenerateReportList?.status &&
      reportStore.entityDetail.status !== 'ERP_SYNCING' &&
      isInRegenerateReportList?.[props.type] !== false)
  if (props.type === 'BALANCE_SHEET' && reportStore.entityDetail.guideStep >= 3 && !regenerating.value) {
    nextTick(() => {
      if (allowDisplayGuidance.value) {
        setTimeout(() => {
          const steps = [
            {
              element: '.elv-reports-menu-right-button .el-button', // 这是添加引导的元素id
              intro: t('bubbleGuide.balanceSheet.step1'), // 这是引导提示内容
              position: 'bottom' // 这是引导位置
            }
          ]
          allowDisplayGuidance.value = false
          initPageIntro(steps, 'balance_sheet')
        }, 500)
      }
    })
  }
}

const onStartRegenerateReport = async () => {
  if (syncTimer.value === null) {
    reportStore.fetchIssueDataList(entityId.value)
    syncTimer.value = setInterval(async () => {
      reportStore.fetchIssueDataList(entityId.value)
      await reportStore.fetchEntityDetail(entityId.value)
      if (regenerating.value && reportStore.entityDetail?.status === 'FINISHED') {
        ElMessage.success(t('report.reportRegeneratedSuccess'))
        if (props.type === 'GENERAL_LEDGER' || props.type === 'SIGNIFICANT_HOLDINGS') {
          emitter.emit('resetList')
        } else {
          emitter.emit('financialsDataInit')
        }
      }
      regenerating.value = reportStore.entityDetail?.status === 'WAITING'
      if (reportStore.entityDetail?.status === 'FINISHED') {
        regenerating.value = false
      }
    }, 5000)
  } else {
    reportStore.fetchIssueDataList(entityId.value)
    await reportStore.fetchEntityDetail(entityId.value)
    regenerateReportInit()
    regenerating.value = reportStore.entityDetail?.status === 'WAITING'
    if (reportStore.entityDetail?.status === 'FINISHED') {
      regenerating.value = false
    }
  }
}

const onClickedRegenerate = async () => {
  try {
    if (!currentEntityPermission.value?.report?.generate) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    if (!regenerating.value) {
      regenerating.value = true
      const response = await ReportsApi.getRegenerateReport(entityId.value)
      if (response.status === 'success') {
        progressPercent.value = 0
        reportStore.entityDetail.progressStatus = 'WAITING'
        reportStore.editRegenerateReport(entityId.value, true)
        nextTick(() => {
          regeneratedSwiperRef.value?.slideTo(0, 0, false)
        })
        onStartRegenerateReport()
      }
    }
  } catch (error: any) {
    console.log(error)
    regenerating.value = false
    ElMessage.error(error.message)
  }
}

emitter.on('onClickedRegenerate', onClickedRegenerate)

watch(
  () => reportStore.entityDetail,
  () => {
    if (financeEntityId.value === 0) {
      financeEntityId.value = reportStore.entityDetail?.entityId
    } else if (financeEntityId.value !== reportStore.entityDetail?.entityId) {
      financeEntityId.value = reportStore.entityDetail?.entityId
      // eslint-disable-next-line no-unused-expressions
      syncTimer.value && clearInterval(syncTimer.value)
      syncTimer.value = null
      // eslint-disable-next-line no-unused-expressions
      progressTimer.value && clearInterval(progressTimer.value)
      progressTimer.value = null
    }
    if (syncTimer.value === null) {
      regenerating.value = reportStore.entityDetail?.status === 'WAITING'
      regenerateReportInit()
      onStartRegenerateReport()
    } else if (syncTimer.value) {
      regenerateReportInit()
    } else {
      regenerating.value = false
      isShowRegeneratedInfo.value = false
    }
  },
  { immediate: true, deep: true }
)

defineExpose({ regenerating, isShowRegeneratedInfo, onClickedRegenerate })

onBeforeUnmount(() => {
  // eslint-disable-next-line no-unused-expressions
  syncTimer.value && clearInterval(syncTimer.value)
  // eslint-disable-next-line no-unused-expressions
  progressTimer.value && clearInterval(progressTimer.value)
  emitter.off('onClickedRegenerate', onClickedRegenerate)
})
</script>

<style lang="scss" scoped>
.elv-report-error-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #1e2024;

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    cursor: pointer;
  }

  img {
    width: 18px;
    height: 18px;
    display: block;
    margin-right: 6px;
  }
}

.elv-financials-header-regenerated {
  width: calc(100% - 40px);
  height: 32px;
  background: #ffffff;
  border: 1px solid #dde1e6;
  border-radius: 2px;
  margin: 12px auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 8px;
  position: relative;

  .elv-financials-header-regenerated-error__icon {
    margin-right: 6px;
  }

  .elv-financials-header-regenerated__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    margin-right: 13px;
  }

  :deep(.el-progress) {
    .el-progress-bar {
      width: 132px;
      height: 6px;
    }

    .el-progress__text {
      .elv-financials-header-regenerated-swiper {
        height: 32px;
        overflow: hidden;
        margin-left: 24px;
      }

      .swiper-wrapper {
        height: 32px;

        .swiper-slide {
          height: 32px;
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 11px;
          line-height: 14px;
          color: #1e2024;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .elv-financials-header-regenerated__close {
    position: absolute;
    right: 10px;
    top: 7px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    fill: #838d95;
  }
}
</style>
